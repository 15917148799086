import { api } from './configs/axiosConfig';

const endPoint = "/users";

export const usersAPI = {
    getPCentersUserWise: async function (userId) {
        const reponse = await api.request({
            url: `${endPoint}/pcenters`,
            method: 'GET'
        });

        return reponse.data;
    },
    getValidMenus: async function () {
        const reponse = await api.request({
            url: `${endPoint}/validmenus`,
            method: 'GET'
        });

        return reponse.data;
    }
}