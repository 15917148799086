import { api } from './configs/axiosConfig';

const endPoint = "/leaves";

export const leaveAPI = {
    getLeaveDetails: async function (fromDate,toDate,pcode) {
        const reponse = await api.request({
            url: endPoint,
            method: 'GET',
            params: { fromDate, toDate, pcode }
        });

        return reponse.data;
    },
    getLeaveType: async function (pcode) {
        const reponse = await api.request({
            url: `${endPoint}/leavetype`,
            method: 'GET',
            params: { pcode }
        });

        return reponse.data;
    },
    saveLeave: async function (leaveDetails,pcode) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { leaveDetails, pcode }
        });

        return response.data;
    },
    approveRejectLeave: async function (empCode, StartLeaveDate, EndLeaveDate, leaveTypeCode, pcode, leaveAutoNo, approveRejectReason, transType, appRejAutoNo,
        levelAutoNo) {
        const response = await api.request({
            url: `${endPoint}/approvereject`,
            method: 'POST',
            data: { empCode, StartLeaveDate, EndLeaveDate, leaveTypeCode, pcode, leaveAutoNo, approveRejectReason, transType, appRejAutoNo, levelAutoNo }
        });

        return response.data;
    },
    getEmployeeLeaveDetails: async function (fromDate, toDate, pcode) {
        const reponse = await api.request({
            url: `${endPoint}/employeeDetails`,
            method: 'GET',
            params: { fromDate, toDate, pcode }
        });

        return reponse.data;
    },
    getBalanceLeaveByLeaveType: async function (empCode, leaveTypeCode, pCode, leaveDate) {
        const reponse = await api.request({
            url: `${endPoint}/balance-leaves`,
            method: 'GET',
            params: { empCode, leaveTypeCode, pCode, leaveDate }
        });

        /*
            response data,
                pendingLeaves = Not approved and partially approved
                partiallyApprovedLeaves = // partially approved only
        */

        return reponse.data;
    },
    getBalanceLeaveForApproval: async function (empCode, leaveTypeCode, pCode, leaveDate, leaveAutoNo) {
        const reponse = await api.request({
            url: `${endPoint}/balance-leaves-approval`,
            method: 'GET',
            params: { empCode, leaveTypeCode, pCode, leaveDate, leaveAutoNo }
        });

        return reponse.data;
    }
}