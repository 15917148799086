import { api } from './configs/axiosConfig';

const endPoint = "/pcenters";

export const pcenterAPI = {
    getPcenters: async function (userId) {
        const reponse = await api.request({
            url: endPoint,
            method: 'GET'
        });

        return reponse.data;
    }
}