import { useEffect, useRef, useState } from "react";
import { usersAPI } from '../../api/usersAPI';
import usePcode from '../../common/hooks/usePcode';

export default function AllPcode({ setSelectedPcode, triggerOnChangePCode = null }) {
    const { loggedPcode } = usePcode();
    const [pcodes, setPcode] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current === true) {

            isFirstRender.current = false;

            setSelectedPcode(loggedPcode);
            usersAPI.getPCentersUserWise().then((data) => setPcode(data));
        }
    }, [])

    const onChangePCode = (e) => {
        setSelectedPcode(e.target.value);
        if (triggerOnChangePCode != null) triggerOnChangePCode(e.target.value);
    }

    return (
        <select className="form-select" onChange={onChangePCode}>
            {
                pcodes.map((data) => (
                    <option key={data.pCode} value={data.pCode} selected={data.pCode === loggedPcode?true:false}>{data.pName}</option>
                ))
            }
        </select>
    );
}