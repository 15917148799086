import { useState } from 'react';
import UserPcode from '../../userPcode';

import { toast } from 'react-toastify';
import { leaveAPI } from '../../../api/leaveAPI';
import useUserName  from '../../../common/hooks/useUserName';

import Spinner from '../../spinner';

export default function LeaveRequest() {
    const { userName } = useUserName();

    const [isProcessing, setIsProcessing] = useState(false);

    const [leaveTypes, setLeaveTypes] = useState([]);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedLeaveType, setSelectedLeaveType] = useState('');
    const [reason, setReason] = useState('');
    const [selectedPCode, setSelectedPcode] = useState('');
    const [isHalfDay, setIsHalfDay] = useState(false);
    const [isShortLeave, setIsShortLeave] = useState(false);

    const [modalAllocatedLeaves, setModalAllocatedLeaves] = useState(0);
    const [modalTakenLeaves, setModalTakenLeaves] = useState(0);
    const [modalPendingLeaves, setModalPendingLeaves] = useState(0);
    const [modalBalanceLeaves, setModalBalanceLeaves] = useState(0);

    const [leaveDetails, setLeaveDetails] = useState([]);

    const addLeaveDetails = () => {
        if (startDate.trim().length === 0) { toast.warn('Please select a startDate'); return; }

        if (endDate.trim().length === 0) { toast.warn('Please select a endDate'); return; }
        
        if (selectedLeaveType.trim().length === 0) { toast.warn('Please select a leave type'); return; }

        if (reason.trim().length === 0) { toast.warn('Please enter a reason'); return; }

        if (new Date(startDate) > new Date(endDate)) { toast.warn('Invalid Date Range'); return; }

        if (new Date(startDate).getFullYear() !== new Date(endDate).getFullYear()) { toast.warn('Leave range should be within same year'); return; }

        if (isHalfDay && isShortLeave) { toast.warn('Leave can not be both short leave & half day'); return; }

        if (startDate !== endDate && (isHalfDay || isShortLeave)) { 
            toast.warn('half day and short leaves are not allowed for multiple days'); 
            return; 
        }

        setLeaveDetails(prevArray => [...prevArray, { startDate, endDate, leaveType: selectedLeaveType, reason, isHalfDay, isShortLeave }]);

        setStartDate('');
        setEndDate('');
        setSelectedLeaveType('');
        setReason('');
        setIsHalfDay(false);
        setIsShortLeave(false);
    }

    const saveLeave = () => {
        if (leaveDetails.length === 0) { toast.warn("Please enter leave details"); return; }

        if (window.confirm("Do you want to save ?") === true) {
            setIsProcessing(true);

            leaveAPI.saveLeave(leaveDetails, selectedPCode).then((data) => {
                resetForm();
                toast.success("Successfully saved");
                console.log("Dimma Test 2");

            }).finally(() => setIsProcessing(false));
        }
    }


    const removeLeave = (index) => {
        setLeaveDetails(prevArray => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });
    }

    const resetForm = () => {
        setLeaveDetails([]);

        setStartDate('');
        setEndDate('');
        setSelectedLeaveType('');
        setReason('');
        setIsHalfDay(false);
        setIsShortLeave(false);

        clearLeaveDetails();
    }

    const clearLeaveDetails = () => {
        setModalAllocatedLeaves(0);
        setModalTakenLeaves(0);
        setModalPendingLeaves(0);
        setModalBalanceLeaves(0);
    }

    const onOpenModal = () => {
        getLeaveTypes(selectedPCode);
    }

    const getLeaveTypes = (pcode) => {
        leaveAPI.getLeaveType(pcode).then((data) => setLeaveTypes(data));
    }

    const onChangePCode = (pcode) => {
        resetForm();
        getLeaveTypes(pcode);
    }

    const onChangeLeaveType = (e) => {
        let selectedLeaveTypeCode = e.target.value;

        setSelectedLeaveType(selectedLeaveTypeCode);
        getBalanceLeaves(startDate,selectedLeaveTypeCode);
    }

    const onChangeStartDate = (e) => {
        let selectedStartDate = e.target.value;

        setStartDate(selectedStartDate);
        getBalanceLeaves(selectedStartDate, selectedLeaveType);
    }

    const getBalanceLeaves = (selectedStartDate,selectedLeaveTypeCode) => {
        clearLeaveDetails();

        if(selectedLeaveTypeCode.trim().length === 0 || selectedStartDate.trim().length === 0) return;

        leaveAPI.getBalanceLeaveByLeaveType(userName,selectedLeaveTypeCode,selectedPCode,selectedStartDate).
        then((data) => {
            setModalAllocatedLeaves(data.allocatedLeaves);
            setModalTakenLeaves(data.takenLeaves);
            setModalPendingLeaves(data.pendingLeaves);
            setModalBalanceLeaves(+data.allocatedLeaves - (+data.takenLeaves + +data.pendingLeaves));
        });
    }

    return (
        <>
            <button type="button" className="form-control btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                onClick={onOpenModal}>Leave Request</button>

            <div className="modal fade modal-xl" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h4 className="modal-title fw-bold" id="staticBackdropLabel">Leave Request</h4>
                            {isProcessing === false && <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>}
                        </div>
                        <div className="modal-body">
                            {isProcessing === true && <div className="text-center"><Spinner /></div>}
                            <form className={isProcessing === true && "visibility--hidden"}>
                                <div className='row '>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <label className='mt-1'> Company : </label>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                                <div className="input-group input-group-sm">
                                                    <UserPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={onChangePCode} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <label className='mt-1'>Start Date : </label>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                <div className="input-group input-group-sm">
                                                    <input onChange={(e) => onChangeStartDate(e)} value={startDate} center type="date" 
                                                        className="form-control" />
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <label className='mt-1'>End Date : </label>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                <div className="input-group input-group-sm">
                                                    <input onChange={(e) => setEndDate(e.target.value)} value={endDate} center type="date" className="form-control" />
                                                </div>
                                            </div>                                            
                                        </div>

                                        <div className='row mt-1'>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <label className='mt-0'> Leave Type : </label>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                <div className="input-group input-group-sm">
                                                    <select onChange={(e) => onChangeLeaveType(e)} value={selectedLeaveType} className="form-select">
                                                        <option value=""></option>
                                                        {
                                                            leaveTypes.map((data) => (
                                                                <option key={data.leaveTypeCode} value={data.leaveTypeCode}>{data.leaveTypeName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-1'>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <label className='mt-1'> Reason : </label>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                                <div className="input-group input-group-sm">
                                                    <textarea onChange={(e) => setReason(e.target.value)} value={reason} className="form-control" maxLength="200" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-1'>
                                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'></div>
                                            <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                <input type="checkbox" checked={isHalfDay} value={isHalfDay} onChange={(e) => setIsHalfDay(e.target.checked)} />
                                                <span className="ms-2">Half Day</span>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                <input type="checkbox" checked={isShortLeave} value={isShortLeave} onChange={(e) => setIsShortLeave(e.target.checked)} />
                                                <span className="ms-2">Short Leave</span>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end'>
                                                <button onClick={addLeaveDetails} type="button" className="btn btn-primary btn-sm">Add</button>
                                            </div>
                                        </div>
                                        <div className='row mt-1 mb-0 p-0'>
                                            <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2'></div>
                                            <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                                <table className="table">
                                                    <tr>
                                                        <td>Allocated leaves : {modalAllocatedLeaves}</td>
                                                        <td>Taken leaves : {modalTakenLeaves}</td>
                                                    </tr>
                                                     <tr>
                                                        <td>Pending leaves : {modalPendingLeaves}</td>
                                                        <td className="fw-bold">Balance leaves : {modalBalanceLeaves}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 table-responsive mt-0 pt-0'>
                                        <table className="table text-nowrap">
                                            <thead className="thead--color">
                                                <tr>
                                                    <td>#</td>
                                                    <td>Date</td>
                                                    <td>Reason</td>
                                                    <td className='text-end pe-3'>Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    leaveDetails.map((data, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.startDate} - {data.endDate}</td>
                                                            <td>{data.reason}</td>
                                                            <td className='text-end '>
                                                                <button onClick={() => removeLeave(index)} type="button" className="btn btn-danger btn-sm">X</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 text-end'>
                                        <button
                                            onClick={saveLeave}
                                            type="button" className="btn btn-primary btn-sm">Save</button>
                                        <button onClick={resetForm} type="button" className="btn btn-danger btn-sm ms-1">Clear</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}