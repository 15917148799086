import { ToastContainer } from "react-toastify";

export default function Alert(){
    return (
        <ToastContainer 
            position="top-center"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
}