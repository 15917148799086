import { useState } from "react";

export default function useUserName() {
    const getUserName = () => {
      const userNameString = localStorage.getItem('user');
      const user = JSON.parse(userNameString);
      return user?.userName
    };
  
    const [userName, setUserName] = useState(getUserName());
  
    const saveUserName = userName => {
      localStorage.setItem('user', `{"userName":"${userName}"}`);
      setUserName(userName);
    };
  
    return {
      setLogedUserName: saveUserName,
      userName
    }
}