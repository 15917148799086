import { LiaHomeSolid } from "react-icons/lia";
import { Routes, Route, Link } from 'react-router-dom';

import './home.css';
export default function Home() {
    

    return (
        <Link to="/mainmenu/mainMenu" className="home">
            <button type="button" className="border border-0 d-flex align-items-center fs-3">< LiaHomeSolid /></button>
        </Link>
    );
}