import {api} from './configs/axiosConfig';

const endPoint = "/authentication";

export const identityAPI = {
    logIn : async function(userName, password, pcode){
        const response = await api.request({
            url : `${endPoint}/logIn`,
            method : 'POST',
            data: { user_Name: userName, password, pcode }
        })

        return response.data;
    }
}
