import axios from 'axios';
import { toast } from 'react-toastify';

export const api = axios.create({
    baseURL: "https://hrm.mcdepot.lk/api/backend"
    //baseURL: "http://localhost:5164/api/backend"
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (error.code === "ERR_CANCELED" || error.code === "ERR_NETWORK") {
        toast.warn("Unable to connect, try again");
        return Promise.resolve();
    }

    if (statusCode && statusCode !== 401) {
        toast.error(error.response.data);
    } else {
        toast.error("Invalid sign in details or Session expired, Please re login");
    }

    return Promise.reject(error)
}

// registering the custom error handler to the "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

// send authorization token and username
api.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    const pcode = JSON.parse(localStorage.getItem('pcode'));

    if (token)
        config.headers.Authorization = `Bearer ${token.token}`
    if (user)
        config.headers.user = user.userName;
    if (pcode)
        config.headers.pcode = user.pcode;

    return config;
})