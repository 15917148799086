import { useState } from "react";

export default function usePcode() {
    const getPcode = () => {
        const pcodeString = localStorage.getItem('pcode');
        const pcode = JSON.parse(pcodeString);
        return pcode?.pcode
    };

    const [pcode, setPcode] = useState(getPcode());

    const savePcode = pcode => {
        localStorage.setItem('pcode', `{"pcode":"${pcode}"}`);
        setPcode(pcode);
    };

    return {
        setLoggedPCode: savePcode,
        loggedPcode: pcode
    }
}